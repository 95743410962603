import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo, useRef } from 'react';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ExchangeWallet } from '@features/exchange-wallet';
import CopyLink from '@shared/ui/misc/CopyLink';
import { ReactComponent as SquareIconComponent } from '@icons/wolfkit-light/square-on-square-light.svg';
import Button from '@shared/ui/buttons/Button';
import { ContainerColumn, ContainerRow } from '@components/styled';
import { BodyMediumSemiBold } from '@components/styled/Typography';
import { PlusIcon } from '@shared/ui/misc/PaymentSelect/icons';
import IconText from '@shared/ui/icons/IconText';
import { ReactComponent as PencilIcon } from '@icons/wolfkit-light/pencil-light.svg';
import { ReactComponent as XMarkIcon } from '@icons/wolfkit-light/xmark-light.svg';
import { ReactComponent as LinkIcon } from '@icons/wolfkit-light/link-alt-light.svg';
import { ReactComponent as UsersIcon } from '@icons/wolfkit-light/user-light.svg';
import Icon from '@shared/ui/icons/Icon';
import { getAbsolutePath } from '@app/routing/helpers';
import useAppNavigation from '@shared/lib/hooks/useAppNavigation';
import { PortfolioCard } from '@entities/portfolio';
import UnsupportedWalletsList from '../UnsupportedWalletsList';
import { ListHeader, ListTitle } from '../shared';
import AddPortfolioButton from '../components/AddPortfolioButton';
import SwitchProfileBanner from '../components/SwitchProfileBanner';
const ExchangeWalletsList = styled(ContainerRow)(props => ({
    gap: props.theme.spacing_sizes.l,
    flexWrap: 'wrap',
}));
const PortfoliosList = styled(ExchangeWalletsList)(props => ({
    marginBottom: props.theme.spacing_sizes.l,
}));
const IconTextStyled = styled(IconText)(props => ({
    gap: props.theme.spacing_sizes.xs * 1.25,
}));
const IconTextError = styled(IconText)(props => ({
    gap: props.theme.spacing_sizes.xs * 1.25,
    '& .MuiTypography-root': {
        color: props.theme.palette.error.main,
    },
}));
const SquareIcon = styled(SquareIconComponent)(props => ({
    padding: 4,
    width: 16,
    height: 16,
    background: props.theme.palette.primary.light,
    borderRadius: 3,
}));
const ApiAddress = styled(BodyMediumSemiBold)(props => ({
    color: props.theme.palette.text.primary,
}));
const CopyButton = styled(Button)(props => ({
    minHeight: 'unset',
    maxHeight: 'unset',
    padding: `${props.theme.spacing_sizes.xs}px ${props.theme.spacing_sizes.xs * 2}px 
              ${props.theme.spacing_sizes.xs * 1.25}px ${props.theme.spacing_sizes.xs * 2}px`,
    gap: props.theme.spacing_sizes.m,
    border: 'unset',
    justifyContent: 'space-between',
}));
const TraderOverview = ({ wallets = [], exchanges = [], portfolios = [], handleClosePortfolio, handleCreatePortfolio, canSwitchProfile = false, profileType, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { routes } = useAppNavigation();
    const copyLinkRef = useRef(null);
    const { supportedWallets, unsupportedWallets, } = useMemo(() => wallets
        .reduce((acc, wallet) => {
        const isSupported = wallet.type === 'MARGIN' || wallet.type === 'SPOT';
        if (isSupported) {
            acc.supportedWallets.push(wallet);
        }
        else {
            acc.unsupportedWallets.push(wallet);
        }
        return acc;
    }, {
        supportedWallets: [],
        unsupportedWallets: [],
    }), [wallets]);
    const handleSuccessCopy = () => { };
    const btnOptions = useMemo(() => (portfolioId) => [
        {
            itemKey: 'api-address',
            children: (_jsx(CopyLink, { ref: copyLinkRef, link: getAbsolutePath(routes.HOME_OVERVIEW), onCopySuccess: handleSuccessCopy, children: _jsx(CopyButton, { endIcon: SquareIcon, size: 'small', variant: 'plain', color: 'primary', fullWidth: true, children: _jsx(ApiAddress, { children: "API: 1234.....01324" }) }) })),
            addDivider: true,
            removePadding: true,
        },
        {
            itemKey: 'edit-portfolio',
            children: (_jsx(IconTextStyled, { IconComponent: _jsx(Icon, { IconComponent: PencilIcon }), text: t('overview.portfolios_list.portfolio_actions.edit_portfolio') })),
            size: 'small',
        },
        {
            itemKey: 'view-data',
            children: (_jsx(IconTextStyled, { IconComponent: _jsx(Icon, { IconComponent: UsersIcon }), text: t('overview.portfolios_list.portfolio_actions.view_data') })),
            size: 'small',
        },
        {
            itemKey: 'copy-link',
            children: (_jsx(IconTextStyled, { IconComponent: _jsx(Icon, { IconComponent: LinkIcon }), text: t('overview.portfolios_list.portfolio_actions.copy_link') })),
            addDivider: true,
            size: 'small',
        },
        {
            itemKey: 'close-portfolio',
            children: (_jsx(IconTextError, { IconComponent: _jsx(Icon, { IconComponent: XMarkIcon, color: theme.palette.error.main }), text: t('overview.portfolios_list.portfolio_actions.close_portfolio') })),
            onClick: () => handleClosePortfolio(portfolioId),
            size: 'small',
        },
    ], []);
    return (_jsxs(ContainerColumn, { children: [_jsx(ListHeader, { children: _jsx(ListTitle, { children: `${t('overview.portfolios_list.title')} (${portfolios.length || 0})` }) }), _jsxs(PortfoliosList, { children: [portfolios.map((portfolio) => (_jsx(PortfolioCard, { portfolio: portfolio, viewType: 'card', moreActionBtnOptions: btnOptions(portfolio.id) }, portfolio.id))), portfolios && portfolios.length < 10 && (_jsx(AddPortfolioButton, { onClick: handleCreatePortfolio }))] }), canSwitchProfile && (_jsx(SwitchProfileBanner, { description: t('portfolio.switch_profile_banner.switch_to_client'), profileType: profileType })), _jsxs(ListHeader, { children: [_jsxs(ListTitle, { disabled: true, children: [t('overview.exchange_wallets.title'), (exchanges === null || exchanges === void 0 ? void 0 : exchanges.length) ? ` (${exchanges.length})` : ''] }), _jsx(Button, { variant: 'tinted', startIcon: PlusIcon, disabled: true, children: t('overview.exchange_wallets.connect_exchange') })] }), _jsx(ExchangeWalletsList, { children: supportedWallets.map((wallet) => (_jsx(ExchangeWallet, { wallet: wallet, portfolios: portfolios, disabled: true }, wallet.id))) }), (unsupportedWallets === null || unsupportedWallets === void 0 ? void 0 : unsupportedWallets.length) > 0 && (_jsx(UnsupportedWalletsList, { count: unsupportedWallets === null || unsupportedWallets === void 0 ? void 0 : unsupportedWallets.length, children: unsupportedWallets.map((wallet) => (_jsx(ExchangeWallet, { wallet: wallet, portfolios: portfolios, disabled: true }, wallet.id))) }))] }));
};
export default TraderOverview;
